

















































































































import CenteredColumnLayout from "@/components/CenteredColumnLayout.vue";
import { InputSetups } from "@/mixins/input-setups";
import {
  createNetwork,
  getMyNetworks,
  initialiseNetworkModel,
} from "@/includes/logic/Networks/logic";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import NetworkNameInput from "@/components/networks/NetworkNameInput.vue";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import UsersInfoMixin from "piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin"

import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { FieldData, SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    NetworkNameInput,
    PageTitle,
    TextInput,
    SelectInput,
    ChipsInput,
    SwitchInput,
    CenteredColumnLayout,
    NestedContent
  }
})
export default class NetworksAdd extends Mixins(InputSetups, NetworksHelperMixin, UsersInfoMixin) {
  networkModel = initialiseNetworkModel()

  isPublic = false

  networksChats: Array<number> | null = null

  usersInfoService = new GetUserInfoServiceImpl(this.chatOptions[0]?.value ?? '')

  get isCreateDisabled() {
    return !this.networkModel.groups.length
  }

  get chatOptions(): Array<SelectOptionData> {
    const baseChats = this.$store.getters.chatSelectOptions

    if (this.networksChats) {
      return baseChats.filter((c: SelectOptionData) => !this.networksChats?.includes(c.value))
    }

    return baseChats
  }

  modelSetter(args: FieldData) {
    args.setter = (value) => this.userInfoModelSetter(
      args,
      { model: value },
      this.networkModel.config?.[args.key as 'global_command_admins' | 'ignored_users'] ?? [],
      this.usersInfoService,
    )

    return args
  }

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }

  createNetworkVm() {
    this.$baseTemplate.loader.open()

    createNetwork(this.networkModel)
      .then(res => {
        this.$baseTemplate.loader.close()

        if (res) {
          this.gotoNetDashboard(res.id)
        }
      })
  }

  mounted() {
    getMyNetworks()
      .then(res => {
        this.networksChats = res.map(n => n.chats).flat()
      })
  }
}
