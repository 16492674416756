
















import { FieldData } from "piramis-base-components/src/components/Pi/types";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';

import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { CreateNetworkReq } from "@/includes/types/networks";

const NETWORK_NAME_MIN_SYMBOLS = '9'

@Component({
  components: {
    TextInput
  },
  data() {
    return {
      NETWORK_NAME_MIN_SYMBOLS
    }
  }
})
export default class NetworkNameInput extends Vue {
  @VModel() name!: CreateNetworkReq['name']

  @Prop({ type: Boolean, required: false, default: false }) hasSettingWrapper!: boolean

  @Prop({ type: String }) prefix!: string

  get settingWrapper() {
    if (this.hasSettingWrapper) {
      return {
        customiseValue: '',
        hasFieldButton: true,
        disabledValue: '',
        'buttonTitle': this.$t('customize')
      }
    }
  }

  networkNameSetter(args: FieldData) {
    args.setter = (value: string) => {
      const trimValue = value.trim()
      const regex = /^[a-zA-Z_0-9]+$/gi

      if (regex.test(trimValue) || !trimValue.length) {
        args.model[args.key] = trimValue
      }
    }

    return args
  }
}
