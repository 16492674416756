var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('text-input',{attrs:{"setup":{
    'func': _vm.networkNameSetter,
    'args': Object.assign({}, {'model': this,
      'key': 'name',
      'prefix': _vm.prefix,
      'allowClear': true,
      'validation': ("min:" + _vm.NETWORK_NAME_MIN_SYMBOLS + "|required")},
      _vm.hasSettingWrapper ? { settingWrapper: _vm.settingWrapper } : undefined)
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }