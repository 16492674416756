var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',{staticClass:"networks-add"},[_c('page-title'),_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'isPublic',
          'prefix': 'networks_',
        }
      }},scopedSlots:_vm._u([{key:"checkedChildren",fn:function(){return [_vm._v(_vm._s(_vm.$t('networks_network_public')))]},proxy:true},{key:"unCheckedChildren",fn:function(){return [_vm._v(_vm._s(_vm.$t('networks_network_private')))]},proxy:true}])}),(_vm.isPublic)?_c('nested-content',[_c('network-name-input',{staticClass:"mt-0 mb-0",attrs:{"has-setting-wrapper":"","prefix":"networks_"},model:{value:(_vm.networkModel.name),callback:function ($$v) {_vm.$set(_vm.networkModel, "name", $$v)},expression:"networkModel.name"}})],1):_vm._e(),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.networkModel,
          'key': 'groups',
          'prefix': 'networks_',
          'options': _vm.chatOptions,
          'multiple': true,
          'validation': 'required',
        }
      }}}),_c('a-divider',[_vm._v(" "+_vm._s(_vm.$t('network_settings'))+" ")]),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.networkModel.config,
          'key': 'access_mode',
          'prefix': 'networks_',
          'options': _vm.accessModeOptions,
          'clearable': false,
          'validation': 'required',
        }
      }}}),_c('a-spin',{attrs:{"spinning":_vm.usersInfoService.isLoading}},[_c('chips-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.networkModel.config,
            'key': 'ignored_users',
            'prefix': 'networks_',
            'settingWrapper': {
              'hasFieldButton': true,
              'disabledValue': [ ],
              'customiseValue': [ ],
            },
            'formatter': _vm.format,
            'disabled': !_vm.chatOptions.length
          }
        }}}),_c('chips-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.networkModel.config,
            'key': 'global_command_admins',
            'prefix': 'networks_',
            'settingWrapper': {
              'hasFieldButton': true,
              'disabledValue': [ ],
              'customiseValue': [ ],
            },
            'formatter': _vm.format,
            'disabled': !_vm.chatOptions.length
          }
        }}})],1),_c('div',{staticClass:"networks-add__footer mt-5 flex gap-2 flex-col sm:flex-row justify-end"},[_c('a-button',{on:{"click":_vm.gotoNetworksList}},[_vm._v(_vm._s(_vm.$t('cancel_button')))]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.isCreateDisabled},on:{"click":_vm.createNetworkVm}},[_vm._v(" "+_vm._s(_vm.$t('create_button'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }